<style>
.notice-style {
  background: #0065ac;
  color: #ffffff;
  font-size: 160%;
}
</style>

<template>
  <div class="app-container">
    <!-- Synchronize To CDN Status -->
    <template v-if="syncCDNStatus === APPLY_STATUS_ENABLE">
      <el-card class="box-card">
        <div>
          <el-button type="primary" size="small" @click="onSyncCDN"
            >Synchronize To Remote CDN Server</el-button
          >
        </div>
      </el-card>
      <br />
    </template>

    <template v-if="syncCDNStatus === APPLY_STATUS_PROCESSING">
      <el-card class="box-card notice-style">
        <div>
          <span
            >Synchronize CDN Processing...{{ syncCDNProcessingTimeInc }}s</span
          >
        </div>
      </el-card>
      <br />
    </template>

    <template v-if="syncCDNStatus === APPLY_STATUS_DONE">
      <el-card class="box-card notice-style">
        <div>
          <span>Synchronization To Remote CDN Server Has Done.</span>
        </div>
      </el-card>
      <br />
    </template>
    <!-- End Synchronize To CDN Status -->

    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="Event List" name="1">
        <el-row :gutter="20">
          <el-col>
            <span>Last Modified: {{ modify }}</span>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input placeholder="" v-model="searchString"></el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" icon="el-icon-search" @click="onSearch()"
              >Search</el-button
            >
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <template v-if="isHolding == 1">
              <el-button type="primary" @click="onHold()"
                >Holding - Click to active</el-button
              >
            </template>
            <template v-else>
              <el-button type="danger" @click="onHold()">
                Activing - Click to hold</el-button
              >
            </template>
            <span> [This will be cancel all editing assets]</span>
          </el-col>
        </el-row>

        <el-table
          :data="configDataList"
          :default-sort="{ prop: 'id', order: 'descending' }"
        >
          <el-table-column prop="id" label="ID" width="100px" sortable>
            <template slot-scope="{ row }">
              {{ row.config_id }}
            </template>
          </el-table-column>

          <el-table-column
            label="EventID"
            prop="config_eventid"
            width="100px"
            sortable
          >
            <template slot-scope="{ row }">
              <span>{{ row.config_eventid }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Event Name" width="200px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <el-input
                  v-model="row.config_eventName"
                  class="edit-input"
                  size="small"
                />
              </template>
              <span v-else>{{ row.config_eventName }}</span>
            </template>
          </el-table-column>

          <el-table-column
            label="Event Type"
            width="200px"
            :filters="[
              //{ text: 'EVENT_DAILY_CHALLENGE', value: 'EVENT_DAILY_CHALLENGE' },
              { text: 'EVENT_LBQ', value: 'EVENT_LBQ' },
              { text: 'EVENT_ENDLESS', value: 'EVENT_ENDLESS' },
              { text: 'EVENT_WHEEL', value: 'EVENT_WHEEL' },
              { text: 'EVENT_BOSS_RAID', value: 'EVENT_BOSS_RAID' },
              { text: 'EVENT_DECORATION', value: 'EVENT_DECORATION' },
              { text: 'EVENT_RECHARGE', value: 'EVENT_RECHARGE' },
              { text: 'EVENT_NONSTOP_OFFFER', value: 'EVENT_NONSTOP_OFFFER' },
              { text: 'EVENT_LUCKY_WHEEL', value: 'EVENT_LUCKY_WHEEL' },
            ]"
            :filter-method="handleFilterEventType"
          >
            <template slot-scope="{ row }">
              <span>{{ row.config_eventType }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Game Version" width="150px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                From:
                <el-input
                  v-model="row.config_fromVersion"
                  class="edit-input"
                  size="small"
                />
                <br /><br />
                To:
                <el-input
                  v-model="row.config_toVersion"
                  class="edit-input"
                  size="small"
                />
              </template>
              <span v-else
                >From: {{ row.config_fromVersion }} <br /><br />
                To: {{ row.config_toVersion }}</span
              >
              <br />
            </template>
          </el-table-column>

          <el-table-column label="Event Date Time" width="250px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                Coming Soon:
                <el-date-picker
                  v-model="row.config_comingsoondatetime"
                  type="datetime"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm:ss"
                >
                </el-date-picker>
                <br /><br />

                From:
                <el-date-picker
                  v-model="row.config_startdatetime"
                  type="datetime"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm:ss"
                  @change="OnChangeEventStartTime($event)"
                >
                </el-date-picker>
                <br /><br />

                To:
                <el-date-picker
                  v-model="row.config_enddatetime"
                  type="datetime"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm:ss"
                  @change="OnChangeEventEndTime($event)"
                >
                </el-date-picker>
              </template>
              <span v-else>
                <span>Coming Soon:</span>
                <span
                  ><li>
                    {{ row.config_comingsoondatetime.toLocaleString("en-GB", { hour12: false }) }}
                  </li></span
                >

                <span>From:</span>
                <span
                  ><li>
                    {{ row.config_startdatetime.toLocaleString("en-GB", { hour12: false }) }}
                  </li></span
                >

                <span>To</span>
                <span
                  ><li>
                    {{ row.config_enddatetime.toLocaleString("en-GB", { hour12: false }) }}
                  </li></span
                >
              </span>

              <br />
            </template>
          </el-table-column>

          <el-table-column label="ConfigIndex" width="150px">
            <template slot-scope="{ row }">
              <span v-if="row.editMode">
                <el-input-number v-model="row.config_index" :step="1" size="small" :min="0"/>
              </span>
              <span v-else>
                {{ row.config_index }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            label="Modifier"
            prop="config_uploader"
            width="180px"
            sortable
            :filters="filterModifiers"
            :filter-method="handleFilterUploader"
          >
            <template slot-scope="{ row }">
              <span>{{ row.config_uploader }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Actions" width="120px">
            <template slot-scope="{ row }">
              <template v-if="row.config_activated != 0">
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="onActive(row)"
                  >Stop!</el-button
                >
              </template>
              <template v-else>
                <template v-if="!row.editMode">
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="onActive(row)"
                    >Active</el-button
                  >
                </template>

                <br />
                <br />
              </template>

              <template v-if="row.config_activated <= 0">
                <template v-if="row.editMode">
                  <template>
                    <el-button
                      type="success"
                      size="small"
                      icon="el-icon-edit-outline"
                      @click="confirmEdit(row)"
                      >Update</el-button
                    >

                    <br />
                    <br />

                    <el-button
                      class="cancel-btn"
                      size="small"
                      icon="el-icon-refresh"
                      type="warning"
                      @click="cancelEdit(row)"
                      >Cancel</el-button
                    >
                  </template>
                </template>
                <template v-else>
                  <el-button
                    type="danger"
                    size="small"
                    icon="el-icon-edit"
                    @click="onEditEvent(row)"
                    >Edit</el-button
                  >
                </template>
              </template>

              <br />
              <br />

              <template v-if="row.config_activated <= 0 && !row.editMode">
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  @click="onDeleteEvent(row)"
                  >Delete</el-button
                >
              </template>
            </template>
          </el-table-column>

          <el-table-column label="Testing" width="200px">
            <template slot-scope="{ row }">
              <template v-if="row.config_activated == 1">
                <template v-if="row.config_testing == Default">
                  <el-button
                    disabled
                    type="primary"
                    @click="onStartTesting(row, Default)"
                    >Default</el-button
                  >
                </template>
                <template v-else>
                  <el-button
                    type="primary"
                    @click="onStartTesting(row, Default)"
                    >Default</el-button
                  >
                </template>

                <br />
                <br />

                <!-- Hide - NON_ACTIVE -->
                <template v-if="row.config_testing == NON_ACTIVE">
                  <el-button
                    disabled
                    type="primary"
                    @click="onStartTesting(row, NON_ACTIVE)"
                    >None Active</el-button
                  >
                </template>
                <template v-else>
                  <el-button
                    type="primary"
                    @click="onStartTesting(row, NON_ACTIVE)"
                    >None Active</el-button
                  >
                </template>

                <br />
                <br />

                <!-- Hide - COMING_SOON -->
                <template v-if="row.config_testing == COMING_SOON">
                  <el-button
                    disabled
                    type="primary"
                    @click="onStartTesting(row, COMING_SOON)"
                    >Coming Soon</el-button
                  >
                </template>
                <template v-else>
                  <el-button
                    type="primary"
                    @click="onStartTesting(row, COMING_SOON)"
                    >Coming Soon</el-button
                  >
                </template>

                <br />
                <br />

                <!-- Show - ACTIVATED-->
                <template v-if="row.config_testing == ACTIVATED">
                  <el-button
                    disabled
                    type="primary"
                    @click="onStartTesting(row, ACTIVATED)"
                    >Activated</el-button
                  >
                </template>
                <template v-else>
                  <el-button
                    type="primary"
                    @click="onStartTesting(row, ACTIVATED)"
                    >Activated</el-button
                  >
                </template>
                <br />
                <br />

                <!-- Hide - EXPIRED -->
                <template v-if="row.config_testing == EXPIRED">
                  <el-button
                    disabled
                    type="primary"
                    @click="onStartTesting(row, EXPIRED)"
                    >Expired</el-button
                  >
                </template>
                <template v-else>
                  <el-button
                    type="primary"
                    @click="onStartTesting(row, EXPIRED)"
                    >Expired</el-button
                  >
                </template>
              </template>

              <template v-if="row.config_activated <= 0">
                <el-button
                  type="primary"
                  icon="el-icon-arrow-up"
                  circle
                  @click="onMoveEventUp(row)"
                ></el-button>

                <br />
                <br />

                <el-button
                  type="primary"
                  icon="el-icon-arrow-down"
                  circle
                  @click="onMoveEventDown(row)"
                ></el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="Add New Event" name="4">
        <el-form ref="form" :model="addEventConfigForm" label-width="200px">
          <el-form-item label="Event ID">
            <el-input
              v-model="addEventConfigForm.eventID"
              @change="onChangeEventID()"
            />
          </el-form-item>

          <el-form-item label="Event Name">
            <el-input v-model="addEventConfigForm.eventName"></el-input>
          </el-form-item>

          <el-form-item label="Event Type">
            <el-select
              v-model="addEventConfigForm.eventType"
              placeholder="Select"
            >
              <el-option
                v-for="item in eventTypeList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Affect From Version" style="width: 50%">
            <el-input
              placeholder="Example: 1.10.0"
              v-model="addEventConfigForm.fromVersion"
            ></el-input>
          </el-form-item>

          <el-form-item label="Affect To Version" style="width: 50%">
            <el-input
              placeholder="Example: 1.11.0"
              v-model="addEventConfigForm.toVersion"
            ></el-input>
          </el-form-item>

          <el-form-item label="Event DateTime">
            <div class="block">
              <el-date-picker
                v-model="addEventConfigForm.eventDateTime"
                type="datetimerange"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
              >
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="Event Coming Soon Date">
            <div class="block">
              <el-date-picker
                v-model="addEventConfigForm.eventComingSoonDateTime"
                type="datetime"
                placeholder="Coming Soon Date"
              >
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="Config Index">
            <el-input-number v-model="addEventConfigForm.configIndex" :step="1" size="small" :min="0"/>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onAddNewEvent"
              >Add New Event</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style>
.el-row {
  margin-bottom: 20px;
}
</style>

<script>
import setting from "@/settings.js";
import { MessageBox, Message } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import { getOSToken } from "@/utils/auth";
import {
  getEventDataFromFirebase,
  activateEventDataFromFirebase,
  activateTestingDataFromFirebase,
  deleteEventFromFirebase,
  uploadEventToFirebaseAsync,
  editEventToFirebase,
  events_holdingSystemToFirebase,
  moveEventFromFirebase,
} from "@/api/dynamic_events";

import { dynamic_assets_holdingSystemToFirebase } from "@/api/dynamic_assets";

import {
  UploadFileToRemoteCDN,
  UploadFileStatus,
  HelperApplyEventTime,
} from "@/api/gameservice";

import store from "@/store";
var fs = require("fs");

var UPLOAD_FOLDER_PATH = "events";

// Status for Apply Settings
const APPLY_STATUS_READY = "READY";
const APPLY_STATUS_ENABLE = "ENABLE";
const APPLY_STATUS_PROCESSING = "PROCESSING";
const APPLY_STATUS_DONE = "DONE";

export default {
  name: "Events",
  components: {},
  data() {
    return {
      addEventConfigForm: {
        eventID: "",
        eventName: "",
        eventType: "",
        fromVersion: "",
        toVersion: "",

        eventDateTime: [Date.now, Date.now],
        fromTime: 0,
        toTime: 0,

        eventComingSoonDateTime: "",
        comingSoonTime: 0,

        user: "",
        fileName: "",
        configIndex: 0,
      },

      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      eventTypeList: [
        //"EVENT_DAILY_CHALLENGE",
        "EVENT_LBQ",
        "EVENT_ENDLESS",
        "EVENT_WHEEL",
        "EVENT_BOSS_RAID",
        "EVENT_DECORATION",
        "EVENT_RECHARGE",
        "EVENT_LUCKY_WHEEL",
        "EVENT_NONSTOP_OFFFER"        
      ],
      configDataList: [],
      uploadFileListOthers: [],
      uploadFileListMain: [],
      filterModifiers: [],
      loading: false,
      selectedName: "",
      selectedFileType: "",
      fromVersion: "",
      toVersion: "",
      searchString: "",
      activeNames: "1",
      isHolding: 0,
      modify: "Hello Admin!",
      log_error: "log_error",
      isChangeEventTime: false,
      CDNUrl: setting.download_file_CDN + "/" + UPLOAD_FOLDER_PATH,
      //----------------------------------
      syncCDNStatus: "",
      syncCDNProcessingTimeInc: 0,
      isSyncCDNTimerRun: false,

      // Apply Settings Statuses
      APPLY_STATUS_READY: APPLY_STATUS_READY,
      APPLY_STATUS_ENABLE: APPLY_STATUS_ENABLE,
      APPLY_STATUS_PROCESSING: APPLY_STATUS_PROCESSING,
      APPLY_STATUS_DONE: APPLY_STATUS_DONE,
      //----------------------------------
    };
  },
  created() {
    this.loading = true;
    this.configDataList = [];
    this.configNameList = [];
    this.loading = false;
    this.modify = "Hello Admin!";

    this.getEventDataList();
    this.onGetUploadFileStatus();

    // defines some constants
    this.Default = "EVENT_DEFAULT";
    this.NON_ACTIVE = "EVENT_NON_ACTIVE";
    this.COMING_SOON = "EVENT_COMING_SOON";
    this.ACTIVATED = "EVENT_ACTIVATED";
    this.EXPIRED = "EVENT_EXPIRED";

    // check OS
    let OS = getOSToken();

    if (OS && OS.toLowerCase() === "ios") {
      this.CDNUrl = setting.download_file_CDN_ios + "/" + UPLOAD_FOLDER_PATH;
    } else {
      this.CDNUrl = setting.download_file_CDN + "/" + UPLOAD_FOLDER_PATH;
    }
  },
  methods: {
    //------------------------------------------------------------------------------------
    // get config data list
    getEventDataList() {
      this.loading = true;
      this.configDataList = [];
      this.filterModifiers = [];
      let filterCheck = [];

      getEventDataFromFirebase()
        .then((response) => {
          let fromDateTime = new Date(response.modify_datetime);
          this.modify = fromDateTime.toString() + " by " + response.modify_user;
          this.isHolding = response.is_holding;

          response.events.forEach((i) => {
            //fromDateTime = new Date(i.datetime);

            let displayDateBegin = new Date(i.date_begin._seconds * 1000);
            let displayDateEnd = new Date(i.date_end._seconds * 1000);
            let displayDateComingSoon = new Date(
              i.date_coming_soon._seconds * 1000
            );

            this.configDataList.push({
              config_id: i.id,
              config_eventid: i.event_id,
              config_eventName: i.event_name,
              config_eventType: i.event_type,
              config_fromVersion: i.affect_from_version,
              config_toVersion: i.affect_to_version,
              config_startdatetime: displayDateBegin,
              config_enddatetime: displayDateEnd,
              config_comingsoondatetime: displayDateComingSoon,

              config_index: i.config_index,
              config_bundlenamemain: i.file_name_list_main,
              config_bundlenameothers: i.file_name_list_others,
              config_uploader: i.uploader,
              config_activated: i.activated,
              config_testing: i.testing,

              editMode: false,
              deleteMode: false,
            });

            if (filterCheck.includes(i.uploader) === false) {
              this.filterModifiers.push({
                text: i.uploader,
                value: i.uploader,
              });
              filterCheck.push(i.uploader);
            }
          });

          this.loading = false;
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config data list: " + error,
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },
    //------------------------------------------------------------------------------------
    // Get Upload File Status
    onGetUploadFileStatus() {
      UploadFileStatus("status_polling").then((response) => {
        //Message({message: "on Get Upload File Status:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

        let code = response.data.code;
        let message = response.data.message;

        if (
          message === APPLY_STATUS_PROCESSING ||
          message === APPLY_STATUS_DONE ||
          message === APPLY_STATUS_ENABLE ||
          message === APPLY_STATUS_READY
        ) {
          if (
            message === APPLY_STATUS_READY &&
            this.syncCDNStatus === APPLY_STATUS_PROCESSING
          ) {
            message = APPLY_STATUS_DONE;
          }

          if (message === APPLY_STATUS_DONE) {
            Message({
              message: "Synchronize CDN ..." + message,
              type: "success",
              duration: 5 * 1000,
            });
          }

          this.syncCDNStatus = message;

          // start a timer to checking status processing next status ...
          //
          if (this.syncCDNStatus === APPLY_STATUS_PROCESSING) {
            // enable loading
            this.loading = true;

            // get information of processing
            let interval = 0;

            if (response.data.info) {
              let info = JSON.parse(response.data.info);
              if (info && info.timestamp) {
                let timestamp = Math.floor(Date.now() / 1000);
                interval = timestamp - info.timestamp;
                if (interval < 0) {
                  interval = 0;
                }
              }
            }

            this.syncCDNProcessingTimeInc = interval;

            // If message is processing auto recheck status continuely
            if (!this.isSyncCDNTimerRun) {
              this.isSyncCDNTimerRun = true;
              setTimeout(() => {
                this.onGetUploadFileStatus();
                this.isSyncCDNTimerRun = false;
              }, 5000);
            }
          } else {
            //disable loading
            this.loading = false;
          }

          //Message({message: "Synchronize CDN ... " + this.syncCDNStatus, type: "success", duration: 5 * 1000});
        } //other status
        else {
          Message({
            message: "Recheck Status ... Unknown status " + message,
            type: "error",
            duration: 5 * 1000,
          });
        }
      });
    },
    //------------------------------------------------------------------------------------
    // Sync method
    onSyncCDN() {
      UploadFileStatus("sync_cdn_polling").then((response) => {
        //this.loading = false;
        //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

        let code = response.data.code;
        if (code === 0) {
          Message({
            message: "[SUCCESS] Synchronize file to remote CDN server",
            type: "success",
            duration: 5 * 1000,
          });

          let message = response.data.message;

          this.syncCDNStatus = message;

          // If message is processing auto recheck status continuously
          if (this.syncCDNStatus === APPLY_STATUS_PROCESSING) {
            this.syncCDNProcessingTimeInc = 0;
            this.isSyncCDNTimerRun = false;
            this.onGetUploadFileStatus();
          }
        } else {
          Message({
            message: "[FAILED] Synchronize file to remote CDN server",
            type: "error",
            duration: 5 * 1000,
          });
        }
      });
    },
    //------------------------------------------------------------------------------------
    // hold method
    onHold() {
      if (this.isHolding === 0) {
        MessageBox.confirm(
          "Do you confirm to HOLD this system?",
          "Confirm HOLD",
          {
            confirmButtonText: "Hold",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.loading = true;

            let promises = [];

            const funcHoldingSystemToFirebase = async (
              is_event,
              is_holding,
              user
            ) => {
              let ret = undefined;

              if (is_event) {
                ret = await events_holdingSystemToFirebase(is_holding, user);
              } else {
                ret = await dynamic_assets_holdingSystemToFirebase(
                  is_holding,
                  user
                );
              }

              return ret;
            };

            promises.push(
              funcHoldingSystemToFirebase(true, 1, store.getters.email)
            );
            promises.push(
              funcHoldingSystemToFirebase(false, 1, store.getters.email)
            );

            Promise.all(promises).then((response) => {
              let success = true;

              for (let i = 0; i < response.length; i++) {
                if (response[i].is_holding === 0) {
                  success = false;
                  break;
                }
              }

              if (success) {
                this.isHolding = 1;
                Message({
                  message: "Holding Success!",
                  type: "success",
                  duration: 5 * 1000,
                });
              } else {
                this.isHolding = 0;
                Message({
                  message: "Holding Failed!",
                  type: "error",
                  duration: 5 * 1000,
                });
              }

              this.loading = false;
            });
          })
          .catch((error) => {
            // do nothing
          });
      } else {
        MessageBox.confirm(
          "Do you confirm to ACTIVE this system?",
          "Confirm ACTIVE",
          {
            confirmButtonText: "Active",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.loading = true;

            let promises = [];

            const funcHoldingSystemToFirebase = async (
              is_event,
              is_holding,
              user
            ) => {
              let ret = undefined;

              if (is_event) {
                ret = await events_holdingSystemToFirebase(is_holding, user);
              } else {
                ret = await dynamic_assets_holdingSystemToFirebase(
                  is_holding,
                  user
                );
              }

              return ret;
            };

            promises.push(
              funcHoldingSystemToFirebase(true, 0, store.getters.email)
            );
            promises.push(
              funcHoldingSystemToFirebase(false, 0, store.getters.email)
            );

            Promise.all(promises).then((response) => {
              let success = true;

              for (let i = 0; i < response.length; i++) {
                if (!response[i]) {
                  success = false;
                  break;
                }
              }

              if (success) {
                this.isHolding = 0;
                Message({
                  message: "Activating Success!",
                  type: "success",
                  duration: 5 * 1000,
                });
              } else {
                this.isHolding = 1;
                Message({
                  message: "Activating Failed!",
                  type: "error",
                  duration: 5 * 1000,
                });
              }

              this.loading = false;
            });
          })
          .catch((error) => {
            // do nothing
          });
      }
    },
    //------------------------------------------------------------------------------------
    onSearch() {
      this.loading = true;
      this.configDataList = [];

      let _searchString = this.searchString;
      getEventDataFromFirebase()
        .then((response) => {
          let fromDateTime = new Date(response.modify_datetime);
          this.modify = fromDateTime.toString() + " by " + response.modify_user;
          response.events.forEach((i) => {
            let displayDateBegin = new Date(i.date_begin._seconds * 1000);
            let displayDateEnd = new Date(i.date_end._seconds * 1000);
            let displayDateComingSoon = new Date(
              i.date_coming_soon._seconds * 1000
            );

            let itemData = {
              config_id: i.id,
              config_eventid: i.event_id,
              config_eventName: i.event_name,
              config_eventType: i.event_type,
              config_fromVersion: i.affect_from_version,
              config_toVersion: i.affect_to_version,
              config_startdatetime: displayDateBegin,
              config_enddatetime: displayDateEnd,
              config_comingsoondatetime: displayDateComingSoon,

              config_index: i.config_index,
              config_bundlenamemain: i.file_name_list_main,
              config_bundlenameothers: i.file_name_list_others,

              config_uploader: i.uploader,
              config_activated: i.activated,

              config_testing: i.testing,

              editMode: false,
              deleteMode: false,
            };

            if (_searchString) {
              let itemString = JSON.stringify(itemData);
              if (itemString.includes(_searchString)) {
                this.configDataList.push(itemData);
              }
            } else {
              this.configDataList.push(itemData);
            }
          });

          this.loading = false;
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config data list",
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },

    //------------------------------------------------------------------------------------
    onEditEvent(row) {
      row.editMode = true;
    },

    //------------------------------------------------------------------------------------
    cancelEdit(row) {
      row.editMode = false;
    },

    // edit mode
    //------------------------------------------------------------------------------------
    confirmEdit(row) {
      if (isStringEmpty(row.config_eventid)) {
        Message({
          message: "Event ID is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(row.config_eventName)) {
        Message({
          message: "Event Name is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      let startTm = new Date(row.config_startdatetime).getTime() / 1000;
      let endTm = new Date(row.config_enddatetime).getTime() / 1000;
      let comingSoonTm =
        new Date(row.config_comingsoondatetime).getTime() / 1000;

      editEventToFirebase(
        row.config_id,
        row.config_eventid,
        row.config_eventName,
        row.config_eventType,
        row.config_fromVersion,
        row.config_toVersion,
        startTm,
        endTm,
        comingSoonTm,
        row.config_activated,
        row.config_testing,
        store.getters.email,
        row.config_index
      )
        .then((response) => {
          row.editMode = false;

          this.loading = false;
          Message({
            message: "Update Success!",
            type: "success",
            duration: 5 * 1000,
          });

          this.getEventDataList();
        })
        .catch((error) => {
          this.loading = false;

          row.editMode = false;

          Message({
            message: "Update Failed! " + error,
            type: "error",
            duration: 5 * 1000,
          });

          Message({
            message: "Update Failed! " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });

      // Send update event time to leaderboard
      if (this.isChangeEventTime) {
        this.isChangeEventTime = false;

        HelperApplyEventTime(row.config_eventid, startTm, endTm)
          .then((response) => {
            if (response.data.code === 0) {
              Message({
                message: `Update event time to leaderboard Success!`,
                type: "success",
                duration: 5 * 1000,
              });
            } else {
              if (response.data.code === 10) {
                Message({
                  message:
                    response.data.message,
                  type: "error",
                  duration: 5 * 1000,
                });
              } else {
                Message({
                  message: `Update event time to leaderboard Failed!`,
                  type: "error",
                  duration: 5 * 1000,
                });
              }
            }
          })
          .catch((error) => {
            //loadingInstance.close();
            Message({
              message: "Update failed: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      }
    },
    OnChangeEventStartTime(event) {
      //Message({message: "OnChangeEventStartTime! " + event, type: "success", duration: 5 * 1000});
      this.isChangeEventTime = true;
    },
    OnChangeEventEndTime(event) {
      //Message({message: "OnChangeEventEndTime! " + event, type: "success", duration: 5 * 1000});
      this.isChangeEventTime = true;
    },

    //------------------------------------------------------------------------------------
    // stop config data
    onActive(row) {
      let count = -1;
      let rowID = 0;
      let rowIdx = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowIdx = count;
          rowID = row.config_id;
        }
      });

      let MsgMessage;
      let confirmButtonText;
      let activeValueNew = 0;

      if (this.configDataList[rowIdx].config_activated <= 0) {
        activeValueNew = 1;
        MsgMessage =
          "Do you confirm START this Event: " + row.config_eventName + "?";
        confirmButtonText = "Activate";
      } else {
        MsgMessage =
          "Do you confirm STOP this Event: " + row.config_eventName + "?";
        confirmButtonText = "Stop";
        activeValueNew = 0;
      }

      MessageBox.confirm(MsgMessage, "Confirm activate", {
        confirmButtonText: confirmButtonText,
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.loading = true;

        this.configDataList[rowIdx].config_activated = activeValueNew;

        row.editMode = false;

        activateEventDataFromFirebase(
          rowID,
          store.getters.email,
          activeValueNew
        )
          .then((response) => {
            Message({
              message:
                confirmButtonText +
                " Event " +
                row.config_eventName +
                " success!" +
                rowID +
                "-" +
                activeValueNew,
              type: "success",
              duration: 5 * 1000,
            });

            this.configDataList[rowIdx].config_uploader = store.getters.email;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    //------------------------------------------------------------------------------------
    // start testing
    onStartTesting(row, testing_type) {
      let count = -1;
      let rowID = 0;
      let rowIdx = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowIdx = count;
          rowID = row.config_id;
        }
      });

      let isTurnTesting = false;
      let MsgMessage;
      let confirmButtonText;

      if (testing_type === this.Default) {
        isTurnTesting = true;
        MsgMessage =
          "Do you confirm DEFAULT Testing this Event: " +
          row.config_eventName +
          "?";
        confirmButtonText = "Default";
      }

      if (testing_type === this.NON_ACTIVE) {
        MsgMessage =
          "Do you confirm NON_ACTIVE Testing this Event: " +
          row.config_eventName +
          "?";
        confirmButtonText = "Non Active";
      }

      if (testing_type === this.COMING_SOON) {
        MsgMessage =
          "Do you confirm COMING_SOON Testing this Event: " +
          row.config_eventName +
          "?";
        confirmButtonText = "Coming Soon";
      }

      if (testing_type === this.ACTIVATED) {
        MsgMessage =
          "Do you confirm ACTIVATED Testing this Event: " +
          row.config_eventName +
          "?";
        confirmButtonText = "Activated";
      }

      if (testing_type === this.EXPIRED) {
        MsgMessage =
          "Do you confirm EXPIRED Testing this Event: " +
          row.config_eventName +
          "?";
        confirmButtonText = "Expired";
      }

      MessageBox.confirm(MsgMessage, "Confirm activate", {
        confirmButtonText: confirmButtonText,
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.loading = true;

        this.loading = false;

        row.editMode = false;

        activateTestingDataFromFirebase(
          rowID,
          store.getters.email,
          testing_type
        )
          .then((response) => {
            Message({
              message:
                confirmButtonText +
                " Event " +
                row.config_eventName +
                " success!",
              type: "success",
              duration: 5 * 1000,
            });

            this.configDataList[rowIdx].config_testing = testing_type;

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    //------------------------------------------------------------------------------------
    // delete config data
    onDeleteEvent(row) {
      let count = -1;
      let rowIdx = 0;
      let rowID = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowIdx = count;
          rowID = i.config_id;
        }
      });

      var eventName = row.config_eventName;

      MessageBox.confirm(
        "Do you confirm delete this Event: " + eventName + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;

        this.configDataList.splice(rowIdx, 1);

        this.loading = false;

        deleteEventFromFirebase(rowID, store.getters.email)
          .then((response) => {
            Message({
              message: "Delete Event Data success!",
              type: "success",
              duration: 5 * 1000,
            });

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    //------------------------------------------------------------------------------------
    // upload one file
    async uploadOneFile(file, fileName) {
      let result = undefined;
      let filePath = UPLOAD_FOLDER_PATH + "/" + this.addEventConfigForm.eventID;

      await UploadFileToRemoteCDN(file.raw, fileName, filePath)
        .then((response) => {
          //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});
          let code = response.data.code;
          if (code === 0) {
            result = "success";
          } else {
            result = "failed";
          }
        })
        .catch((error) => {
          result = "failed";
        });

      return result;
    },

    //------------------------------------------------------------------------------------
    // upload multi file
    async uploadMultiFile(fileList, nowTime) {
      let fileName = "unknown";
      let result = [];

      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];

        fileName = "unknown";
        if (file.name) {
          fileName = file.name;
        }

        fileName += "_" + nowTime;

        await this.uploadOneFile(file, fileName)
          .then((response) => {
            let data = {
              file_name: file.name,
              result: response,
            };
            result.push(data);
          })
          .catch((error) => {
            let data = {
              file_name: file.name,
              result: "failed",
            };
            result.push(data);
          });
      }

      return result;
    },

    //------------------------------------------------------------------------------------
    // add config name
    async onAddNewEvent() {
      if (isStringEmpty(this.addEventConfigForm.eventID)) {
        Message({
          message: "Event ID: is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (!isStringEmpty(this.addEventConfigForm.eventID)) {
        this.configDataList.forEach((configData) => {
          if (configData.config_eventid === this.addEventConfigForm.eventID) {
            Message({
              message: `Event ID: ${configData.config_eventid} is existed, eventName = ${configData.config_eventName}`,
              type: "error",
              duration: 5 * 1000,
            });
            return;
          }
        });
      }

      if (isStringEmpty(this.addEventConfigForm.eventName)) {
        Message({
          message: "Event Name: is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.addEventConfigForm.fromVersion)) {
        Message({
          message: "Affect From Version: is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.addEventConfigForm.toVersion)) {
        Message({
          message: "Affect To Version: is empty!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      let dateFrom = new Date(this.addEventConfigForm.eventDateTime[0]);
      this.addEventConfigForm.fromTime = dateFrom.getTime() / 1000;

      let dateTo = new Date(this.addEventConfigForm.eventDateTime[1]);
      this.addEventConfigForm.toTime = dateTo.getTime() / 1000;

      let dateComingSoon = new Date(
          this.addEventConfigForm.eventComingSoonDateTime
      );
      this.addEventConfigForm.comingSoonTime = dateComingSoon.getTime() / 1000;

      this.loading = true;

      // default not active event
      let isActivated = 0;
      let isTesting = this.Default;

      let fileNameListMain = [];
      let fileNameListOthers = [];

      if (this.addEventConfigForm.eventType === "EVENT_LBQ") {
        fileNameListMain.push("event_lbq_cf");
        fileNameListOthers.push("event_lbq_data");
      }
      else if (this.addEventConfigForm.eventType === "EVENT_ENDLESS") {
        fileNameListMain.push("event_endless_cf");
        fileNameListOthers.push("event_endless_data");
      }
      else if (this.addEventConfigForm.eventType === "EVENT_WHEEL") {
        fileNameListMain.push("event_wheel_cf");
      }
      else if (this.addEventConfigForm.eventType === "EVENT_BOSS_RAID") {
        fileNameListMain.push("event_bossraid_cf");
        fileNameListOthers.push("event_bossraid_data");
      }
      else if (this.addEventConfigForm.eventType === "EVENT_DECORATION") {
        fileNameListMain.push("event_decoration_cf");
      }
      else if (this.addEventConfigForm.eventType === "EVENT_RECHARGE") {
        fileNameListMain.push("event_recharge_cf");
      }
      else if (this.addEventConfigForm.eventType === "EVENT_LUCKY_WHEEL") {
        fileNameListMain.push("event_lucky_wheel_cf");
        fileNameListOthers.push("event_lucky_wheel_data");
      }
      else if (this.addEventConfigForm.eventType === "EVENT_NONSTOP_OFFFER") {
        fileNameListMain.push("event_nonstop_offer_cf");
      }

      let fileSizeListMain = [];
      let fileSizeListOthers = [];

      //not use now -> fake 9999
      for (let i = 0; i < fileNameListMain.length; i++) {
        fileSizeListMain.push(9999);
      }

      //not use now -> fake 9999
      for (let i = 0; i < fileNameListOthers.length; i++) {
        fileSizeListOthers.push(9999);
      }

      try {
        await uploadEventToFirebaseAsync(
            this.addEventConfigForm.eventID,
            this.addEventConfigForm.eventName,
            this.addEventConfigForm.eventType,
            this.addEventConfigForm.fromVersion,
            this.addEventConfigForm.toVersion,
            this.addEventConfigForm.fromTime,
            this.addEventConfigForm.toTime,
            this.addEventConfigForm.comingSoonTime,
            isActivated,
            isTesting,
            store.getters.email,
            fileNameListMain,
            fileNameListOthers,
            fileSizeListMain,
            fileSizeListOthers,
            this.addEventConfigForm.configIndex
        );

        Message({
          message: "Added Success!",
          type: "success",
          duration: 5 * 1000,
        });

        this.addEventConfigForm.eventID = "";
        this.addEventConfigForm.eventName = "";
        this.addEventConfigForm.eventType = "";
        this.addEventConfigForm.fromVersion = "";
        this.addEventConfigForm.toVersion = "";

        this.getEventDataList();
        //this.onGetUploadFileStatus();
      } catch (error) {
        Message({
          message: "Added Failed! " + error,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
    },

    //------------------------------------------------------------------------------------
    // on Move Event Up
    onMoveEventUp(row) {
      if (this.isHolding === 0) {
        Message({
          message: "You need to HOLD the system!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      let count = -1;
      let rowIdx = 0;
      let rowID = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowIdx = count;
          rowID = i.config_id;
        }
      });

      var eventName = row.config_eventName;

      MessageBox.confirm(
        "Do you confirm Move Up this Event: [" + rowID + "]" + eventName + "?",
        "Confirm Move",
        {
          confirmButtonText: "Move Up",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;

        moveEventFromFirebase(1, rowID, store.getters.email)
          .then((response) => {
            Message({
              message: "Move Event Up success!",
              type: "success",
              duration: 5 * 1000,
            });

            this.getEventDataList();

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    //------------------------------------------------------------------------------------
    // on Move Event Down
    onMoveEventDown(row) {
      if (this.isHolding === 0) {
        Message({
          message: "You need to HOLD the system!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      let count = -1;
      let rowIdx = 0;
      let rowID = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowIdx = count;
          rowID = i.config_id;
        }
      });

      var eventName = row.config_eventName;

      MessageBox.confirm(
        "Do you confirm Move Down this Event: [" +
          rowID +
          "]" +
          eventName +
          "?",
        "Confirm Move",
        {
          confirmButtonText: "Move Down",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;

        moveEventFromFirebase(2, rowID, store.getters.email)
          .then((response) => {
            Message({
              message: "Move Event Down success!",
              type: "success",
              duration: 5 * 1000,
            });

            this.getEventDataList();

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    //------------------------------------------------------------------------------------
    //onChangeEventID
    onChangeEventID() {
      if (!isStringEmpty(this.addEventConfigForm.eventID)) {
        this.configDataList.forEach((configData) => {
          if (configData.config_eventid === this.addEventConfigForm.eventID) {
            Message({
              message: `Event ID: ${configData.config_eventid} is existed, eventName = ${configData.config_eventName}`,
              type: "error",
              duration: 5 * 1000,
            });
            return;
          }
        });
      }
    },

    //------------------------------------------------------------------------------------
    // handle Remove and change Browse File
    handleRemoveOthers(file, fileList) {
      this.uploadFileListOthers = fileList;
    },

    //------------------------------------------------------------------------------------
    handleChangeOthers(file, fileList) {
      this.uploadFileListOthers = fileList;
    },

    //------------------------------------------------------------------------------------
    // handle Remove and change Browse File
    handleRemoveMain(file, fileList) {
      this.uploadFileListMain = fileList;
    },

    //------------------------------------------------------------------------------------
    handleChangeMain(file, fileList) {
      this.uploadFileListMain = fileList;
    },

    //------------------------------------------------------------------------------------
    // others
    handleClick(obj, e) {
      if (obj.label === "Event List") {
        // refresh upload file status
        this.onGetUploadFileStatus();
      }
    },
    //------------------------------------------------------------------------------------
    // handleFilterEventType
    handleFilterEventType(value, row) {
      return row.config_eventType === value;
    },
    //------------------------------------------------------------------------------------
    // handleFilterUploader
    handleFilterUploader(value, row) {
      return row.config_uploader === value;
    },
  },
};
</script>

<style lang="scss" scoped>
.events-container {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
